html,
body,
#root {
  height: 100%;
  background-color: #ffffff;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  letter-spacing: 0.1rem;
}

body {
  font-size: 1rem;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}

h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}

p {
  font-size: 14px;
  margin: 0 0 10px;
}

b,
strong {
  font-weight: 700;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #eee;
  color: #3c4858;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}
*:focus {
  outline: 0 !important;
}
